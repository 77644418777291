import React, { useRef, useEffect, useContext } from 'react';
import gsap from 'gsap';

import { Link } from 'components/link/Link';
import { Container } from 'components/container/Container';
import { Image } from 'components/contentful/image/Image';
import { Button } from 'components/button/Button';

import { scroll } from 'constants/Scroll'

import { PrismicImage } from 'types/prismicImage';

import { UIContext } from 'context/ui';

import s from './MediaGrid.scss';

interface IProps {
  imageLeft: PrismicImage;
  imageLeftLabel?: string;
  imageLeftTitle?: string;
  imageLeftLink?: string;
  imageRight: PrismicImage;
  imageRightTitle?: string;
  imageRightLabel?: string;
  imageRightLink?: string;
  isLastComponentOnPage?:boolean;
  bgHalfBlue?:boolean;
  hasHover?:boolean;
  grid?: boolean;
  useAnchor?: boolean;
}

export const MediaGridContentful = (
  { 
    mediaGrid,
    isLastComponentOnPage,
    bgHalfBlue=false,
    hasHover=false,
    grid=false,
    useAnchor= false
  }: any) => {

  const { isDesktop } = useContext(UIContext);
  const imageRight = mediaGrid.imageRight
  const imageRightTitle = mediaGrid.imageRightTitle
  const rightButtonLabel = mediaGrid.rightButtonLabel
  const rightButtonLink = mediaGrid.rightButtonLink

  const imageLeft = mediaGrid.imageLeft
  const imageLeftTitle = mediaGrid.imageLeftTitle
  const leftButtonLabel = mediaGrid.leftButtonLabel
  const leftButtonLink = mediaGrid.leftButtonLink

  const content = (image:PrismicImage, link?:string, title?:string, label?:string, hasHover?:boolean) => {

    const innerContent = (
      <>
        <div className={s.mediaGrid__image}>
          <Image image={image} aspectRatio='102%' />
        </div>
        <div className={s.mediaGrid__content}>
          <div>
          {title && <div className={s.mediaGrid__label}>{title}</div>}
          {label && (
            <div className={s.mediaGrid__button}>
              <Button label={label} invertedColor={true} ></Button>
            </div>
          )}
          </div>
        </div>
      </>
    );

    if (link) {
      if(!useAnchor) {
        return(
          <Link className={s.mediaGrid__button} to={link}> {innerContent} </Link>
        )
      } else {
        return(
          <a className={s.mediaGrid__button} href={link}> {innerContent} </a>
        )
      }
    }

    return (innerContent)
  };

  const elRef = useRef<HTMLDivElement>(null);
  const firstImageRef = useRef<HTMLDivElement>(null);
  const secondImageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isDesktop) return;

    if (elRef.current && firstImageRef.current && secondImageRef.current && !grid) {
      gsap.fromTo(
        firstImageRef.current,
        { y: -50 },
        { y: 50, scrollTrigger: { scroller: scroll.container, trigger: elRef.current, scrub: true } }
      );

      gsap.fromTo(
        secondImageRef.current,
        { y: 50 },
        { y: -50, scrollTrigger: { trigger: elRef.current, scrub: true } }
      )
    }
  }, [isDesktop])

  return (
    <div ref={elRef} className={ s('mediaGrid', {isLastComponentOnPage, bgHalfBlue, hasHover, grid}) }>
      <div className={s.mediaGrid__bgHalfBlueElement}></div>
      <Container>
        <h2 className={`u-visually-hidden`}>Media Grid</h2>
        <div className={s.mediaGrid__row}>

          <div ref={firstImageRef} className={s('mediaGrid__col', 'left')}>
            <div className={s.mediaGrid__item}>
              {content(imageLeft, leftButtonLink, imageLeftTitle, leftButtonLabel)}
            </div>
          </div>

          <div ref={secondImageRef} className={s('mediaGrid__col', 'right')}>
            <div className={s.mediaGrid__item}>
              {content(imageRight, rightButtonLink, imageRightTitle, rightButtonLabel)}
            </div>
          </div>

        </div>
      </Container>
    </div>
  )
}