import React, { useContext } from 'react';

import { Container } from 'components/container/Container';
import { RichText } from 'components/contentful/rich-text/RichText';
import { Button } from 'components/button/Button';
// import { Link } from 'components/link/Link';

import { PrismicRichText } from 'types/prismicRichText';

import s from './Text.scss';

import { UIContext } from 'context/ui';

interface IProps {
  title?: string;
  text1: PrismicRichText;
  text2?: PrismicRichText;
  cta?: {
    label: string;
    href: string;
  };
  stacked?: boolean;
  layout?: 'default' | 'grid';
}

export const Text = ({
  text,
  stacked,
  layout='default'
}:
any) => {
  
  const { isMobile, isDesktop } = useContext(UIContext);
  const title = text.title
  const text1 = text.text1
  const text2 = text.text2
  const cta = text.cta
  return (
    <div className={s('text', stacked ? 'stacked' : '', layout+'Layout')}>
      <Container>
        <div className={s.text__row}>
          <div className={s.text__col}>
            <h2 className={s.text__title}>{title}</h2>
          </div>
          <div className={s('text__col','right')}>
            { cta && isDesktop && (layout == 'grid' || stacked) && 
              <div className={s.text__ctaContainer}><Button label={cta.label} href={cta.href} ></Button></div>
            }
            { text1 && (layout == 'default' || stacked) && 
              <RichText richText={text1}/>
            }
          </div>
        </div>
        { layout == 'grid' && (
        <div className={s('text__row','bottom')}>
          <div className={s.text__col}>
            { text1 && layout == 'grid' &&
              <RichText richText={text1}/>
            }
          </div>
          <div className={s('text__col','right')}>
            {cta && isMobile && 
              <div className={s('text__ctaContainer','isMobile')}><Button label={cta.label} href={cta.href} ></Button></div>
            }
            {text2 && layout == 'grid' &&
              <div className={s.text__extraTextContainer}><RichText richText={text2}/></div>
            }
          </div>
        </div>
        )}
      </Container>
    </div>
  );
};
