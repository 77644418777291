import React, { useEffect, useRef } from 'react';
import s from './BgLine2.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"

export const BgLine2 = () => {

  const lineRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout( () => {
      gsap.fromTo(lineRef.current, {
        drawSVG: '100% 100%'
      }, {
        drawSVG: '100% 0%',
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 150%",
          end: '300% 35%+=100px',
          once: true,
        },
      });
    }, 1500 )
    return () => timer && clearTimeout(timer);
  }, []);

  return (
    <div className={s.section__draw}>
      <svg className={s.path__draw__1} xmlns="http://www.w3.org/2000/svg" width="4000" height="380" viewBox="0 0 4000 380" fill="none">
        <path 
          ref={lineRef} 
          className="section__draw"
          fill="none"
          strokeMiterlimit="10"
          d="M4000,379H1789c-104.7,0-189.5-84.3-189.5-189l0,0c0-104.7-84.8-189-189.5-189H0"/>
      </svg>
      <svg className={s.path__draw__2} xmlns="http://www.w3.org/2000/svg" width="4000" height="380" viewBox="0 0 4000 380" fill="none">
        <path 
          d="M4000,379H1789c-104.7,0-189.5-84.3-189.5-189l0,0c0-104.7-84.8-189-189.5-189H0"/>
      </svg>
    </div>
  );
};