import React, { useEffect, useRef } from 'react';
import s from './BgLineMobile2.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"

export const BgLineMobile2 = () => {

  const lineRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout( () => {
      gsap.fromTo(lineRef.current, {
        drawSVG: '100% 100%'
      }, {
        drawSVG: '100% 0%',
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 100%",
          end: "bottom 50%+=100px",
          once: true,
        },
      });
    }, 1500 )
    return () => timer && clearTimeout(timer);
  }, []);

  return (
    <div id="section2__draw" className={s.section2__draw}>
      <svg className={s.path__draw__1} xmlns="http://www.w3.org/2000/svg" width="90.9" height="287.7" viewBox="0 0 90.9 287.7" fill="none">
        <path 
          ref={lineRef}
          className="section__draw | js-tr-trigger" 
          d="M1,287.7V51C1,23.4,23.4,1,51,1h39.9"/>
      </svg>

      <svg className={s.path__draw__2} xmlns="http://www.w3.org/2000/svg" width="90.9" height="287.7" viewBox="0 0 90.9 287.7" fill="none">
        <path d="M1,287.7V51C1,23.4,23.4,1,51,1h39.9"/>
      </svg>
    </div>
  );
};
