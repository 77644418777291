import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import { Prismic_Product_New } from 'types/prismic';

import { Seo } from 'components/contentful/seo/Seo';
import { Billboard } from 'components/contentful/billboard/Billboard';
import { BillboardTextContentful } from 'components/contentful/billboard-text/BillboardText';
import { InfoListContentful } from 'components/contentful/info-list/InfoList';
import { ListContentful } from 'components/contentful/list/List';
import { QuoteContentful } from 'components/contentful/quote/Quote';
import { Text } from 'components/contentful/text/Text';
import { MediaText } from 'components/media-text/MediaText';
import { MediaTextContentful } from 'components/contentful/media-text/MediaText';
import { MediaGridContentful } from 'components/contentful/media-grid/MediaGrid';
import { VideoGrid } from 'components/video-grid/VideoGrid';
import { BgGradient } from 'components/bg-svgs/gradients/BgGradient';

import { WrapperBgs } from 'components/container/WrapperBgs'
import { BgLine1 } from 'components/bg-svgs/lines/home/bg-line-1/BgLine1';
import { BgLine2 } from 'components/bg-svgs/lines/technology/bg-line-2/BgLine2';

import { BgLineMobile1 } from 'components/bg-svgs/lines/home/bg-line-mobile-1/BgLineMobile1';
import { BgLineMobile2 } from 'components/bg-svgs/lines/technology/bg-line-mobile-2/BgLineMobile2';

import { UIContext } from 'context/ui';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

interface IProps {
  data?: {
    prismic?: {
      technology?: Prismic_Product_New;
    };
  };
  location?: Location;
}

// tslint:disable no-default-export
export default ({ data, location }: any) => {
  if (!data) return null;
  const technology: Prismic_Product_New | null = data?.prismic?.product_new! ?? null;
  const { isMobile, isDesktop } = useContext(UIContext);
  const contentfulData = data.contentfulData
  const billboard = contentfulData.billboard1
  const mediaGrid1 = contentfulData.mediaGrid1
  const mediaText1 = contentfulData.mediaText1
  const infoList1 = contentfulData.infoList1
  const infoList2 = contentfulData.infoList2
  const quote1 = contentfulData.quote1
  const mediaText2 = contentfulData.mediaText2
  const list1 = contentfulData.list1
  const billboardText1 = contentfulData.billboard2
  const mediaText3 = contentfulData.mediaText3
  const list2 = contentfulData.list2
  const text1 = contentfulData.text1
  const mediaText4 = contentfulData.mediaText4
  const infoList3 = contentfulData.infoList3
  const infoList4 = contentfulData.infoList4
  const quote2 = contentfulData.quote2
  const infoList5 = contentfulData.infoList5
  const quote3 = contentfulData.quote3
  const seo = data.contentfulData.compose__page[0].seo

  return (
    <>

      <Seo seo={seo}/>

      <BgGradient />

      {isDesktop && <BgLine1 />}
      {isMobile && <BgLineMobile1 />}

      <WrapperBgs>
        <Billboard
          transparentBg={true}
          vertical={true}
          textCenter={true}
          lessPadding={true}
          billboard={billboard}
        />
      </WrapperBgs>

      <WrapperBgs>
        <MediaGridContentful
          mediaGrid={mediaGrid1}
          bgHalfBlue={false}
          grid={true}
          useAnchor={true}
        />

      </WrapperBgs>

      <WrapperBgs id="moveGoods">

        <MediaTextContentful
          mediaText={mediaText1}
          layout="column"
          extraSpacingBottom={false}
          inverted={true}
          topRadius={true}
          noAspectRatio={true}
        />

        <InfoListContentful
          infoList={infoList1}
          titlePlacement="inside"
          titleAlignTop={false}
          lessPaddingTop={false}
          lessPaddingBottom={true}
          borderRadius="bottomRight"
          evenColumns={true}
        />
        <InfoListContentful
          infoList={infoList2}
          titlePlacement="inside"
          titleAlignTop={false}
          borderRadius="bottomLeft"
          imageAlignment="left"
          contentExtraMarginTop={true}
          lessPaddingTop={true}
          lessPaddingBottom={true}
          evenColumns={true}
        />
        <QuoteContentful
          paddingTop={'24rem'}
          quote={quote1}
          smaller_text={true}
          italic={true}
        />
        { isDesktop && <BgLine2 />}
        {isMobile && <BgLineMobile2 />}
      </WrapperBgs>

      <WrapperBgs extraClassName="altBlue">
        <MediaTextContentful
          mediaText={mediaText2}
          layout="column"
          disableScroll={true}
          noSpacingBottom={true}
          narrow={true}
          inverted={true}
        />
        
        <ListContentful
          list={list1}
          centerTitle={true}
          itemColumns={true}
          noPaddingTop={true}
        />

        <BillboardTextContentful
          billboardText={billboardText1}
          lessSpacing={true}
        />

        <MediaTextContentful
          mediaText={mediaText3}
          noSpacingTop={true}
          layout="column"
          extraSpacingBottom={false}
          inverted={true}
          topRadius={true}
          noAspectRatio={true}
        />

        <ListContentful
          list={list2}
          centerTitle={true}
          itemColumns={true}
          background={true}
          darker={true}
        />
    
      </WrapperBgs>

      <WrapperBgs>
        
        <Text
          text={text1}
          stacked={true}
        />

        { Object.keys(mediaText4).length > 0 &&
          <MediaTextContentful
          mediaText={mediaText4}
          layout="column"
          disableScroll={true}
          noSpacingBottom={true}
          narrow={true}
          inverted={true}
        />

        }

{/*         {technology.tech_05_videos && 
          <VideoGrid
            videos={technology.tech_05_videos}
            height={360}
        />
        } */}

      </WrapperBgs>

      <WrapperBgs id="movePeople">

        <InfoListContentful
          infoList={infoList3}
          titlePlacement="inside"
          titleAlignTop={true}
          lessPaddingTop={false}
          lessPaddingBottom={true}
          borderRadius="bottomRight"
          evenColumns={true}
        />
        <InfoListContentful
          infoList={infoList4}
          titlePlacement="inside"
          titleAlignTop={false}
          borderRadius="bottomLeft"
          imageAlignment="left"
          lessPaddingTop={true}
          extraPaddingBottom={true}
          evenColumns={true}
        />
      </WrapperBgs>

 {/*      {technology.tech_10_videos.length > 0 && 
      <WrapperBgs extraClassName="altBlue">
        { technology.tech_10_videos_title && technology.tech_10_videos_title[0].text &&

        <MediaText
          title={technology.tech_10_videos_title && technology.tech_10_videos_title[0].text}
          layout="column"
          disableScroll={true}
          noSpacingBottom={true}
          narrow={true}
          inverted={true}
        />
        }
        {technology.tech_10_videos && 
          <VideoGrid
            videos={technology.tech_10_videos}
            height={360}
        />
        }

        <Text
          text={null}
          stacked={true}
        />

      </WrapperBgs>
      } */}

      <WrapperBgs>
        <QuoteContentful
          quote={quote2}
          smaller_text={true}
          italic={true}
        />
      </WrapperBgs>


      <WrapperBgs>
       <InfoListContentful
          infoList={infoList5}
          titlePlacement="inside"
          titleAlignTop={true}
          lessPaddingTop={true}
          extraPaddingBottom={true}
          borderRadius="top"
        />
      </WrapperBgs>


      <WrapperBgs>

        <QuoteContentful
          quote={quote3}
          smaller_text={true}
          italic={true}
        />

      </WrapperBgs>

      

    </>
  );
};

export const query = graphql`
{
    contentfulData: contentfulOurServicePageType(
      compose__page: {elemMatch: {slug: {eq: "our-service"}}}
    ) {
      billboard1 {
        text {
          raw
        }
        title
      }
      billboard2 {
        title
        text {
          raw
        }
      }
      infoList1 {
        description {
          raw
        }
        title
        items {
          title
          text {
            raw
          }
          video {
            file {
              url
            }
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      infoList2 {
        description {
          raw
        }
        title
        items {
          title
          text {
            raw
          }
          video {
            file {
              url
            }
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      infoList3 {
        description {
          raw
        }
        title
        items {
          title
          text {
            raw
          }
          video {
            file {
              url
            }
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      infoList4 {
        description {
          raw
        }
        title
        items {
          title
          text {
            raw
          }
          video {
            file {
              url
            }
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      infoList5 {
        description {
          raw
        }
        title
        items {
          title
          text {
            raw
          }
          video {
            file {
              url
            }
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      list1 {
        title
        text {
          raw
        }
        items {
          title
          text {
            raw
          }
          image {
            title
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            description
          }
          video {
            description
            file {
              url
            }
          }
        }
      }
      list2 {
        title
        text {
          raw
        }
        items {
          title
          text {
            raw
          }
          image {
            title
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            description
          }
          video {
            description
            file {
              url
            }
          }
        }
      }
      mediaGrid1 {
        imageLeft {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        imageLeftTitle
        imageRight {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        imageRightTitle
        leftButtonLabel
        leftButtonLink
        rightButtonLabel
        rightButtonLink
      }
      mediaText1 {
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        title
        text {
          raw
        }
        video {
          file {
            url
          }
        }
        sectionBackground {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
          description
        }
      }
      mediaText2 {
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        title
        text {
          raw
        }
        video {
          file {
            url
          }
        }
        sectionBackground {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
          description
        }
      }
      mediaText3 {
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        title
        text {
          raw
        }
        video {
          file {
            url
          }
        }
        sectionBackground {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
          description
        }
      }
      mediaText4 {
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        title
        text {
          raw
        }
        video {
          file {
            url
          }
        }
        sectionBackground {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
          description
        }
      }
      quote1 {
        author
        avatar {
          description
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        text {
          raw
        }
      }
      quote2 {
        author
        avatar {
          description
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        text {
          raw
        }
      }
      quote3 {
        author
        avatar {
          description
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        text {
          raw
        }
      }
      text1 {
        title
        text2 {
          raw
        }
        text1 {
          raw
        }
        cta {
          href
          label
        }
      }
      compose__page {
        seo {
          description
          title
          twitterImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          facebookImage {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
        title
      }
      compose__page {
        seo {
          description
          title
          twitterImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          facebookImage {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
        title
      }
    }
  }
`;