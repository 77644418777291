import React, { useEffect, useRef } from 'react';
import s from './BgLineMobile1.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
gsap.registerPlugin(DrawSVGPlugin);

export const BgLineMobile1 = () => {

  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: '0%',
      },
      {
        duration: 2.5,
        delay: 1.5,
        drawSVG: '100%',
        ease: 'linear',
      }
    );
  },[])

  return (
    <div id="hero__line" className={s.hero__line}>
      <svg width="57" height="690" viewBox="0 0 57 690" xmlns="http://www.w3.org/2000/svg">
        <path
          ref={lineRef}
          fill="none"
          strokeMiterlimit="10"
          d="M1,0v591c0,39.4,22.8,73.5,56,89.8"
        />
      </svg>
    </div>
  );
};
